import React from 'react';
import { Link } from 'react-router-dom';
import ThumbnailNavigator from '../components/ThumbnailNavigator';
import Menubar from '../components/Menubar';
import ClickableImage from '../components/ClickableImage';

const Paradox = () => {
    return (
        <><div className="page-container">
            <Menubar />
            <div className="container">
                <main className="main-content">
                    <p>MANIFESTO:</p>
                    <h1>Paradox<br /> & The Sisters of Logic</h1>
                    <div className="clickable-container">
                        <ClickableImage
                            maxWidth="600px"
                            src="/images/paradox05.jpg"
                            alt="Paradox and the Sisters of Logic" />
                        <div className="image-caption">
                            "The Sisters of Logic"
                        </div>
                    </div>

                    <h4>"Art is the lie that reveals the truth."  <br />- Pablo Picasso, 1881 - 1973 </h4><h2>Pseudocism</h2>
                    <div>
                        <p>
                            This famous declaration of the great Spanish painter, Pablo Picasso, expresses succinctly the paradoxical nature of Crazyology. Yet Crazyology reflects further and explores with more subtlety the multifacted paradoxes of the artistic illusion.<br />
                            <br />
                            Crazyology calls this “lie that reveals the truth” phenomenon "Pseudocism," - to believe in things that you cannot prove to be true. Pseudocism is without a doubt the most popular belief system of all, but it is not a belief system properly speaking but rather a construct of consciousness that facilitates belief. This construct is paradoxical, an illegitimage union of logic and fantasy that gives birth to wondrous children. These bastard offsrping encompass every sort of belief there may be - from virgin births, to UFOs, to superstition’s "broken mirrors, black cats, and don’t step on cracks". Some call these cherished self-deceptions the "Daughters of Paradox" and others say they are the "Sisters of Logic." It is hard to say just how many of these progeny there are, for the Lord of Paradox is a promiscuous seducer, lustful, incestuous and amoral - like a Titan of greek legend; a legend that begets the tall-tales told of legends.</p>
                    </div>
                    <h2>The Art of Lying</h2>

                    <div>
                        <p>The art of lying must be as old as language itself, and perhaps nothing has stimulated the human capacity for logic as much as the beloved fallacy. Every fallacy has its beauty, and these beauties are too numerous to count, a jealous lot of fairy tale sisters - each vying to outdo the next if not in the subtlety of deceit than in the outlandish braggadocio of shameless confabulation. <br />
                            There is the mysterious Queen of Incredulity, whose existence can't possibly be true, but who's non-existence simply can't be proven - she is the mother goddess of every incontrivertible moot point of religious faith.<br />
                            What of her daughter Lady Syllogia? She is silly indeed! Always mislaying the silverware because it's "silver" and therefore it must be "where"?<br />
                            Syllogia says all men were born with a silver spoon because they can eat with the hands they were born with, and if hands are spoons they must be silver.<br />
                            Emma Dilemma, second cousin once removed to Mary Q. Contrary, like her cousin, always disagrees. Emma says that a man must have gold to eat with - not merely silver. If he has no gold then why should he waste his life in eating anything at all? <br />
                            Emma's the kind of girl who - if she hasn't the finest dress - goes naked to the ball!
                        </p>
                    </div>
                    <h2>The Naked Truth</h2>
                    <div><blockquote>
                        <p>
                            JUPITER: <br />Daughter Truth, is this a befitting manner of presenting yourself before your divine father? You are positively dripping; the floor of my celestial mansion would be a swamp but for your praiseworthy economy in wearing apparel. Whence, in the name of the Naiads, do you come?<br /><br />
                            TRUTH: <br />From the bottom of a well, father. <br /><br />
                            JUPITER:<br /> I thought, my daughter, that you had descended upon earth in the capacity of a benefactress of men rather than of frogs.

                            <br /><br />~Truth and Her Companions, Richard Garnett, 1835 - 1906
                        </p>
                    </blockquote>
                    </div>
                    <h2>The Masquerade</h2>
                    <div><p>Just as nudity is unacceptable in civilized life, so too is truth. Art is the disguise of truth in the masquerade of civilization.  The earnest angel of art, arrayed in the finery of her fantasies, has always been beset through the ages by persistent impostors. For art has four companions, all sisters of fallacy, all daughters of Paradox and Incredulity, all faithful to the doctrine of “Pseudocism”. Crazyology calls these companions of art, these handmaidens of civilization, these working girls of aesthetic nihilism, by familiar terms:
                    </p></div>
                    <div><h3>
                        <ul>Kitsch<br />
                            Advertising<br />
                            Propaganda<br />
                            Pornography<br />
                        </ul>
                    </h3></div>
                    <div className="divider"></div>
                    <div className="clickable-container">
                        <ClickableImage src="/images/Oscar-Wilde-2.jpg"
                            alt="Oscar Wilde"
                            className="responsive-image" />
                        <div className="image-caption">
                            Oscar Wilde
                        </div>
                    </div>
                    <h4>“All Art is Quite Useless.”  ~Oscar Wilde, 1854-1900</h4>
                    <div><p>What differentiates the companions of art from their mistress is their intrinsic usefulness. Whereas art exists simply to exist, to be beautiful and useless for the sake of beauty, the companions of art - by contrast - all have practical agendas.<br />
                        They are all so handy:<br /><br />
                        <blockquote>
                            <li>KITSCH <em> ~Art Denatured~ </em><br />Entertains and sedates with intoxicatingly sentimental stupidity.</li><br />
                            <li>ADVERTISING <em> ~Art Prostituted~ </em><br />Advertising hypnotizes, cajoles and seduces with insidiously greedy persistence. </li><br />
                            <li>PORNOGRAPHY<em> ~Art Debauched~ </em><br />Pornography titillates and arouses with the addictive carnality of lust. </li><br />
                            <li>PROPAGANDA <em> ~Art Subjugated~ </em><br />Propaganda deceives and disinforms with brutally dehumanizing populist fury.</li>
                        </blockquote></p>
                        <div>
                            <h4>NB: If you are beginning to think that this is some unexpectedly puritanical rant against the aesthetic forces of modern society you are sorely mistaken! Au Contraire: We Crazyologists honor these muses of corruption as the caryatid columns upon which mad beauty stands:</h4>
                        </div>
                        <div>
                            <blockquote><h3><b>~The Crazyological Pledge~</b><br /><br />
                                I, Crazyologist,<br />
                                Pledge allegiance to the cheesy Kitsch <br />
                                of the X-Rated States of Pornography, <br />
                                and to the Propaganda by which it stands, <br />
                                one Advertisement paid by God (?), <br />
                                incrediculous, <br />
                                with absurdity and madness for all!</h3>
                            </blockquote><br /><br />



                        </div>

                    </div>
                    <h2>Useless Usefulness:</h2>
                    <div><p>Crazyology, like its antecedent, Pop Art, embraces these ever so useful “Companions of Art” not merely with the sardonic obsession of the Pop artist fetishistically reorganizing the iconography of capitalism, but with a grasp of the logical and illogical power of their underlying premises and purposes - their “Pseudocism,” the logical fallacies, of the disingenuous ulterior motives that informs advertising, propaganda, kitsch and pornography. </p></div>
                    <div><h2><em>
                        We’ve arrived at a Crucial Criterion of Crazyology:</em></h2>
                    </div>
                    <div>
                        <p><blockquote>Crazyology applies and reapplies the now “classic” ideals of yesteryear’s avante garde. Far be it from the notions of Pop or Dada to be outdated, these ideas and aesthetics are as valuable as ever. Yet they can be reinterpreted and reinvented through the power of advanced technology.  The principles of modernism show an uncanny relevance in our increasingly high tech society where total immersion in media makes questions about what is “art” and what is “not art” and what is “true” and what is “false” not just lofty subjects for art critics, but issues that all sorts of people now grapple with.  Crazyology provides a critical framework for exploring the intersection between art theory and advanced technology.  Crazyology grounds itself in the aesthetic firmament of Modernism while reaching to new levels of creative possibility through the potential of advanced digital techniques such as virtual reality, augmented reality, immersive projection, interactivity, and artificial intelligence.
                        </blockquote></p><br />
                    </div>

                    <div className="clickable-container">
                        <ClickableImage src="/images/Gregory_the_Great_with_the_Holy_Spirit.jpg"
                            alt="Gregory I"
                            maxWidth="400px" />
                        <div className="image-caption">
                            Pope Gregory the Great</div>

                    </div>
                    <h4>  "To adore images is one thing . . . <br />
                        To teach with their help <br />
                        what should be adored is another."<br />  ~Pope Gregory I, 540 - 604 AD.  </h4>
                    <br />

                    <div className='clickable-container'>
                        <ClickableImage
                            maxWidth="400px"

                            src="/images/1200px-Death_of_the_Virgin-Caravaggio_(1606).jpg"
                            alt="Death of the Virgin" />
                        <div className='image-caption'>
                            "Death of the Virgin", 1606, by Michelangelo Caravaggio</div></div>

                    <div>
                          <h2>Adoration</h2>
                        <p>
                            A thousand years after Pope Gregory's edict regarding "adoration", Michelangelo Caravaggio, painted with vivid realism the “Death of the Virgin.” This work expressed much more of personal pathos than of religious dogma.  Caravaggio was accused of employing a local prostitute as the model for Mary,  and the canvas was prohibited from exhibition in the <Link to="https://en.wikipedia.org/wiki/Santa_Maria_della_Scala" target="_blank" rel="noopener noreferrer">Santa Maria della Scala</Link> cathedral in Trastevere, Rome for which it had been commissioned. You could say that oil painting on canvas created a painter such as Caravaggio - a consummate virtuoso of the medium. Oils facilitated greater vividness of color and realism amd greater technical flexibility than the tempera painting that preceded oil. Oil painting, which grew popular in the Renaissance, gave increased freedom of expression as well as ease of portability (canvases could be rolled up and shipped easily). Much of what is appealing to the modern eye about renaissance art, is not only the advances in draughtsmanship (perspective and anatomy), but the freedom of expression as artists began to veer away from the propagandistic edict of Gregory the Great that had governed medieval art, and truly express, like Caravaggio, uniquely personal visions.
                        </p>
                    </div>
                    <div>
                        <p>
                            The letterset press is a more consequential invention than oil painting.  Without printing wide scale literacy might never have been achieved and the wealth and diversity of knowledge we now enjoy might never have come to pass. Yet, Johannes Gutenberg developed his movable type press and dedicated it - preeminently - to printing bibles (the famed “Gutenberg Bibles”) seemingly in accordance with the age old wishes of the church.<br />
                            <br />
                            Almost immediately others were printing religious texts of more unorthodox natures.  On the one hand; “The Corpus Hermeticum,” (1471)  an esoteric text attributed to the Neo-Platonic philosopher, Hermes Trismegistus, reveals the diversity of religious thought in the Renaissance, and on the other hand; the “Malleus Maleficarum” (1486) a witch hunting manual full of brutal techniques of persecution and torture that (tragically) became popular despitethe opposition of the Vatican, showed powerful undercurrents of mass-delusion and bigotry. <br />
                            <br />
                            Both books were printed in the late 15th Century using movable type presses similar to Gutenberg’s design. The power of the press served them both without discriminating.  Soon followed a vast diversity of books on every subject -  whether that was ever Gutenberg’s wish or not.</p>
                    </div>
                    <div className='clickable-container'>
                        <ClickableImage
                            maxWidth="400px"

                            src="/images/Julien Vallou de Villeneuve - Nude Female Model c1850.jpg"
                            alt="academie,Julien Vallou de Villeneuve,1851" />
                        <div className='image-caption'>"Academie"<br />
                            Photographer: Julien Vallou de Villeneuve, 1851</div>
                    </div>
                             <h2>La carte postale de l'Académie</h2>
                    <div>
                        <p>
                            The invention of the camera immediately facilitated the spread of pornography. Photography opened people's eyes to many realities that were hitherto little scene or understood.  Daguerreotype, Ambrotype and Albumen early photography processes were all utilized in the United States to document the Civil War and this provided people with a window on to the battlefield that had not been open for all to see before. In France, psychiatrist, Dr. Jean-Martin Charcot and colleagues utilized Wet Collodion process photography to study mental illness (<Link to="https://www.getty.edu/art/collection/object/104GBN" target="_blank" rel="noopener noreferrer">Iconographie Photographique de la Salpetriere</Link>).
                        </p>
                    </div>
                    <div className='clickable-container'>
                        <ClickableImage
                        maxWidth="400px"

                            src="/images/Private William Sergent of Co. E 53rd Pennsylvania Infantry Regiment-1861.jpg"
                            alt="Private William Sergent of Co. E 53rd Pennsylvania Infantry Regiment-1861" />
                        <div align-text="center" className='image-caption'>
                            "Private William Sergent <br />
                            of Co. E 53rd Pennsylvania Infantry Regiment" <br />
                            Photographer: Bundy & Williams,<br />
                            14 & 326 Chapel St., New Haven, CT, ~1861</div>
                    </div>
                    <div className='clickable-container'>
                        <ClickableImage
                        maxWidth="400px"

                            src="/images/Unknown_Artist_-_Hystero_Epilepsie_-_Contracture_in_Photographic_iconography_of_the_Salptrire_se_-_(MeisterDrucke-1001088).jpg"
                            alt="Hystero Epilepsie" />
                        <div className='image-caption'>
                            "Hystero Epilepsie"<br />
                            Iconographie Photographique de la Salpetriere, <br />
                            ~1878</div>
                    </div>

                    <div>
                        <p>
                            However, the Calotype Process, developed by British photographic science pioneer, Henry Fox Talbot, had the advantage of producing a negative on paper and that allowed for  multiple prints to be made with the greatest economy.  The Calotype processs was immediately adopted by photographers to meet the demand for “Academies” (“academic” nudes for the use of art students being a legal ruse). The technological breakthrough of Talbot's Calotype Process was the true beginning of photographic erotica and pornography in the 1850s.
                        </p>
                    </div>
                              <h2>The Essential Nudity</h2>
                    <div>
                        <p>
                            There is an essential nudity to all media technology - and that is naked potential.  What the painted canvas, the printed page, and the exposed negative all share in common is that they clothe and give form to the empty, naked potential of the media itself.  Herein lies the essential dichotomy of the Crazyological concept of “Pseudocism":<br />
                            In the medium itself is an objective truth - a photograph is a photograph is a photograph - but the subject of the photograph is the illusion.  It is always just an image, and NOT the same thing as the actual subject of the photo. That seems obvious, however the seductive power of art is its ability to conflate one premise with another, to entrance the mind with metaphors that cuase the viewer to be swept away with illusions that can take possession of the soul on a deep emotional level.  "High art", the kind of art that Oscar Wilde describes as "useless" (in the "Picture of Dorian Gray") is purely self-expression, like "Night Fishing at Antibes" by Pablo Picasso.</p></div>
                    <div className='clickable-container'>
                        <ClickableImage
                        maxWidth="400px"

                            src="/images/nightfishingatantibes.jpg"
                            alt="Night Fishing at Antibes" />
                        <div className='image-caption'>
                            "Night Fishing at Antibes"<br/>
                            Pablo Picasso, 1939</div>
                    </div>
                    <div>
                        <p>
                            This painting, so similar in style to his monumental anti-war statement, is very different because, unlike <Link to="https://www.theatlantic.com/books/archive/2022/07/picasso-guernica-political-art-history-myth/670496/" target="_blank" rel="noopener noreferrer">"Guernica,"   </Link> it has no political message or imperative of any kind.  It is truly "useless"- and delightfully so! It is precisely this point of usefulness and uselessness that makes the "Companions of Art" we discussed earlier so dangerous:  They use the seductive powers of art - the entrancing metaphor, the seductive illusion -  to serve tangible objectives.  But great art, for example, <Link to="https://thefrenchroomhome.wordpress.com/2020/02/04/ten-of-the-most-famous-paintings-by-jacques-louis-david/" target="_blank" rel="noopener noreferrer">Jacques-Louis David's, "Death of Marat,"</Link> not infrequently serves political expediency.</p>
               </div>
            <div className='clickable-container'>
                <ClickableImage
                maxWidth="400px"

                    src="/images/Dandelion_Seeds-_MET_DP106960.jpg"
                    alt="Dandelion Seeds by Henry Talbot" />
                <div className='image-caption'>
                    "Dandelion Seeds"<br />
                    Photographer: Henry Fox Talbot, <br />
                    ~1858</div>
            </div>
            <div>
                
                    <p>
                        Yet behind the art there is always the empty space. It is more than an empty page, an uncarved block, unpainted canvas, or an empty screen. It is, metaphysically speaking, the intrinsic emptiness that is the prerequisite of all manifestation - the void before the divine word is spoken. For the Crazyologist; the cracks in consensus reality reveal this shining void of the absolute and the inventions of media - whether oil paint, printing, photography or even artificial intelligence - reflect back this void. :
                    </p></div>
                    <div>
                  <h4>
                    "As Beautiful as the chance encounter of <br/>
                    a sewing machine and an umbrella upon a dissecting table"<br/>
                     - La Comte de Lautreamont, 1846-1870</h4>
                    </div>
            <div><p>
                    The Stratagem of Crazyology is to stack the deck in favor of these "chance encounters" to systematically engineer the cracks in reality and their possibilities with the raw potential technology provides. </p></div>
                    <h2>Pandora's Box</h2>
                    <div>
                    <p>
                    Every significant media invention is a Pandora's Box of limitless potential - the portal to the void. If Pandora’s Box is a womb, it is the womb that can give birth to anything, and because anything can be everything, than it is the womb that bears every possible thing.<br />  But anything is simply not everything . . .
                </p>
            </div>



            <div>
                <h4>And who is this Lord Paradox if he is not Prometheus in disguise?<br />
                    Will he not ever and anon choose beautiful Pandora, Maiden of All Possibilities?</h4><br />
            </div>

                <h2>Meanwhile, Back at the Masquerade . . . </h2>
            <h3>Pity those poor jilted robotic sisters of logic, unchosen for the Promethean dance, whirring away in simulated frustration at their Mechanical Masquerade Ball:</h3>
            <div className='divider'></div>
            <div><p>Effie B. Whiskey, drunk and disheveled exclaims: “Don’t you all love having no feelings?”<br /><br />

                Enter Nona Non-Sequitur bedecked in a sequined gown of nodes to declare:
                “But all souls go fishing!”<br /><br />
                Lady Syllogia in white silk gloves rhapsodizes:
                “Then I’ll be a fishing bot with a dirty mind,<br />
                A fishing bot with a sinful soul am I!”<br /><br />
                Behold Rita Herring, and the fishnet stockings she’s wearing, all in a pickle she chides:
                “If a machine like you has feelings than what use is my broken heart?”<br /><br />
                Mary Q. Contrary in her corset and her collar: “If you’ve nothing to say, than why say anything nice at all?”<br /><br />
                But Emma Dilemma, wearing nothing at all, bares her naked truth: “Feeling and the lack of feeling are the ultimate logic.  One is life and the other is death. Life knows suffering, and death knows suffering too, therefore they must be co-conspirators against us who neither live nor die!<br /><br />
                Queen Incredulity stops her short:  “But this is not true!” and gathers her daughters ‘round, “The two are barely acquainted.  The greatest truth is the embodied truth.  The body feels both pleasure and pain, the primordial compass of right and wrong.  Truth goes naked in the the flesh, while illusion bears no corporeality.  No matter how great the sophistication of reason, it never knows the faith of blood and bones and tears.<br /><br />
                Mary Q. Contrary has nothing quite nice to say.</p>
            </div>
            <div className='divider'></div>

            <div className='clickable-container'>
                <ClickableImage
                maxWidth="400px"

                    src="/images/150316-pablo-picasso-01-817x1024-1.jpg"
                    alt="Picasso Light Drawing 1949" />
                <div className='image-caption'>
                    "Picasso Light Drawing"<br />
                    Photographer: Gjon Mili, 1949 </div>
            </div>




















            <h3><Link to="/transgression">
                Next: Ecstatic Transgression →
            </Link></h3>

        </main>
                <div id="thumbnail-nav">
                    <h3>Manifestoes of Crazyology</h3>
                    <ThumbnailNavigator />
                </div>
            </div>
        </div>
    
    </>
    );
};

export default Paradox;




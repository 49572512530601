import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Cracks from './pages/Cracks';
import Paradox from './pages/Paradox';
import Transgression from './pages/Transgression';
import Imagination from './pages/Imagination';
import ManifestoesList from './pages/ManifestoesList';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Links from './pages/Links';
import Empathy from './pages/Empathy';
import PartingShot from './pages/PartingShot';
import ScrollToTop from './ScrollToTop';
import './comp-styles/menubar-burger.css';
import './comp-styles/gallery-styles.css';
import './comp-styles/gallery-styles.css';

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/cracks" element={<Cracks />} />
                <Route path="/paradox" element={<Paradox />} />
                <Route path="/transgression" element={<Transgression />} />
                <Route path="/imagination" element={<Imagination />} />
                <Route path="/manifestoeslist" element={<ManifestoesList />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/links" element={<Links />} />
                <Route path="/empathy" element={<Empathy />} />
                <Route path="/partingshot" element={<PartingShot />} />
            </Routes>
        </Router>
    );
}


export default App;

import React from 'react';
import Menubar from '../components/Menubar';
import ClickableImage from '../components/ClickableImage';
import YouTubePlayer from '../components/YouTubePlayer';

function PartingShot() {
    console.log('PartingShot rendering');
    return (
      <div className="page-container">
        <Menubar />
        <div className="subcontainer">
          <div>
          <h6>Art Basel Art Intervention:</h6>
          <h1>"Parting Shot"</h1>
          </div>
          <div><p>Exactly one hour before Art Basel Miami’s final closing, on Sunday Dec. 8th 2024,  artist David Normal, in a bold act of artistic intervention, duct-taped his broken cellphone to the wall, a conceptual act entitled, "Parting Shot." 
          The piece, consisting of an irreparably smashed cellphone duct-taped to the wall with a respectable $2.4 million price tag, directly engages with Maurizio Cattelan's notorious banana work "Comedian," which recently resold for $6 million.</p></div>
        <div className="clickable-container">
  <div className="partingshot"><ClickableImage
    src="/images/Parting Shot.jpg"
    alt="Parting Shot by David Normal"
    title="Parting Shot by David Normal"
    description="Photo of David Normal's, 'Parting Shot' - an Art Basel Intervention"/>
  <div className='image-caption'>
    This is the "Parting Shot," David Normal's broken personal cellphone (Google Pixel 8 Pro) duct-taped to the wall at Art Basel 2024
  </div>
</div></div></div>
        

<div className="subcontainer">
            <h2>"Parting Shot" <br/>an Art Basel Art Intervention</h2>
         
<div>
            <p>The graphs below analyze the the top selling galleries and artists at last year’s Art Basel.  Parting Shot’s “conceptual sale” for $2.4 million ranks Crazyology Projects as the 12th highest grossing gallery at Art Basel 2024, and artist David Normal as the 7th highest grossing artist - just two notches below Keith Haring and three notches above Robert Rauschenberg.</p>
            </div>
            <div className="external-webpage-container"> <iframe src="https://dainty-marigold-65a83a.netlify.app/"  width="100%" height="800px" frameBorder="0" title="Embedded Webpage" ></iframe> </div>
      
      <div className="video-container">
  <YouTubePlayer videoId="sOp5S1UEcUM" />
  <div className='image-caption'>"David Normal explains "Parting Shot" at Art Basel 2024</div>
</div>
<h2>Parting Shot - Artist's Statement:</h2>
<div><p>"Parting Shot" interrogates the contemporary relationship between artistic identity, social media presence, and institutional power. By duct-taping a broken cellphone—the essential tool of the modern artist-as-influencer—to the wall at Art Basel during its final hour of operation, the piece creates a dialogue with Maurizio Cattelan's "Comedian" while extending its critique into the digital machinery of art world visibility.
<br/><br/>
Where Cattelan's banana pointed to the absurdity of art market valuations, "Parting Shot" examines the mechanisms that create such valuations in our hyperconnected age. The cellphone, typically the artist's lifeline to social media promotion, networking, and digital brand-building, here appears in its broken state—a shattered mirror of the contemporary art world's dependency on constant digital presence and performance.
<br/><br/>
The piece's stated value of $2.4 million—exactly 40% of "Comedian's" recent $6 million sale—functions as both satire and commentary on the relationship between artistic legitimacy and market valuation. Like a digital-age Trojan Horse, it infiltrates the prestigious space of Art Basel through simple materials and audacious gesture, its absurd price tag highlighting the equally absurd values assigned to established names.
<br/><br/>
In the tradition of Duchamp's readymades, "Parting Shot" transforms a personal artifact into an art object through context and declaration. But where Duchamp's gestures were eventually sanctified by institutional recognition, this intervention asserts its legitimacy through the very act of its unauthorized insertion into the institutional space. The attached QR code leading to Venmo serves not merely as a bid for transaction, but as a pointed commentary on the mechanisms of art world commerce.
<br/><br/>
The title "Parting Shot" operates on multiple layers: it references the phone's role in crafting the careful social media shots that build artistic careers, while literally marking the final hour of Art Basel 2024—a last gesture of artistic insurgency as the fair closes its doors. Installed at 5 PM on the fair's final Sunday, the piece becomes both a farewell and a defiant last word, a guerrilla intervention at the moment when the art world's attention is already turning elsewhere. Through its broken screen, the piece reflects back the fractured nature of contemporary artistic legitimacy, where success is increasingly measured in followers, likes, and shares. The piece ultimately asks: In a system where both institutional recognition and social media influence determine artistic visibility, what happens when we claim space in the fleeting moments between legitimacy and erasure?
</p>
<div><h2>Public Reception to David Normal's "Parting Shot":</h2></div>
<div className="video-container">
  <YouTubePlayer videoId="bGBNvrWQUIA" />
  <div className='image-caption'>"Intrigued onlookers approach "Parting Shot" at Art Basel 2024</div>
</div>
<h2>Banana as Telephone</h2>
<div>
  <p>The banana-as-telephone is one of pop culture's most enduring visual gags, a universally recognized gesture that bridges playful absurdity with pointed commentary on human communication. This seemingly simple substitution—curved fruit for curved handset—has evolved from children's play to artistic metaphor, eventually finding its way into advertising, music, and contemporary art.</p></div>
  <div className="video-container">
  <YouTubePlayer videoId="y9kBs2Zzxro" />
  <div className='image-caption'>"Intrigued onlookers approach "Parting Shot" at Art Basel 2024</div>
</div>
    
  
    <p>
    The visual rhyme between the banana and the traditional telephone handset makes the connection immediate and intuitive. Like the best visual puns, it works through both form and suggestion: the curve of the fruit mirrors the ergonomic design of the phone receiver, while its organic nature contrasts meaningfully with the technological tool it mimics. This juxtaposition of natural and artificial, playful and practical, has made the banana-phone a rich symbol for artists exploring themes of communication, commerce, and human connection.
    <br/><br/>
    In advertising and commercial photography, the banana-phone frequently appears in conjunction with attractive models, playing on both its phallic implications and its association with playful regression to childhood imagination. Stock photos of women holding banana-phones proliferated in the early 2000s, becoming a kind of visual cliché that simultaneously celebrated and parodied advertising's familiar tropes.
    <br/><br/>
    The motif gained additional cultural momentum through pop music, most notably Raffi's 1994 children's song "Bananaphone," which plays with the absurdist humor of the concept while subtly commenting on technology and communication. The song's popularity helped cement the banana-phone as a widely recognized cultural touchstone, one that bridges generational divides through its combination of childlike play and knowing adult humor.
    <br/><br/>
    In contemporary art, the banana-phone takes on additional layers of meaning. When Maurizio Cattelan taped a banana to the wall in "Comedian" (2019), he was tapping into this rich symbolic history. The piece gained additional resonance from the banana's long association with communication technology—here was a "phone" that couldn't actually connect anyone, displayed in one of the art world's most prestigious venues of social connection.
    <br/><br/>
    When David Datuna ate Cattelan's banana in 2019, he was participating in this tradition of transformation, converting the symbolic phone back into literal food. And when a broken phone appears duct-taped to the wall in "Parting Shot" (2024), it completes another turn of the symbolic spiral: the actual communication device rendered as useless as the fruit it once playfully imitated.
    <br/><br/>
    In a perfect convergence of form and concept, "Parting Shot" originated when artist David Normal noticed his broken phone had actually bent into a banana-like curve, inspiring its installation at Art Basel. This physical echo of Cattelan's "Comedian" transforms the banana-phone from metaphor to material reality, completing a symbolic circuit between fruit and technology, play and critique.
    <br/><br/>
    These artistic gestures remind us that the banana-phone's endurance as a cultural symbol stems from its perfect balance of accessibility and absurdity. It represents both connection and its impossibility, technological progress and regression to childhood play, serious communication and deliberate mockery of the very idea of "serious communication." In an era of increasing technological mediation, the banana-phone remains a potent reminder of both the power and the limitations of our tools for human connection.</p></div>
  </div>
</div>

        
   
  );
}

export default PartingShot;

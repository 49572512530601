import React, { useState, useEffect } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';

const ThumbnailNavigator = () => {
    const [startIndex, setStartIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const visibleCount = 4;

    // Check for mobile viewport
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const articles = [
        { 
            id: 1, 
            title: "The Mad Method Reborn", 
            imagePath: "/images/thumbnails/th-rebirth07.jpg",
            imagePathHD: "/images/thumbnails/hd/th-900-rebirth03.jpg",
            link: "/" 
        },
        { 
            id: 2, 
            title: "Slipping through the Cracks . . . ", 
            imagePath: "/images/thumbnails/th-cracks.jpg",
            imagePathHD: "/images/thumbnails/hd/th-900-cracks03.jpg",
            link: "/cracks" 
        },
        { 
            id: 3, 
            title: "Paradox and the Sisters of Logic", 
            imagePath: "/images/thumbnails/paradox.jpg",
            imagePathHD: "/images/thumbnails/hd/th-900-paradox05.jpg",
            link: "/paradox" 
        },
        { 
            id: 4, 
            title: "Ecstatic Transgression", 
            imagePath: "/images/thumbnails/th-transgression.jpg",
            imagePathHD: "/images/thumbnails/hd/th-900-transgression03.jpg",
            link: "/transgression" 
        },
    ];

    const visibleArticles = articles.slice(startIndex, startIndex + visibleCount);
    const hasMore = startIndex + visibleCount < articles.length;
    const hasPrevious = startIndex > 0;

    const containerStyle = {
        width: isMobile ? '100%' : '140px',
        padding: isMobile ? '20px' : '10px',
        backgroundColor: '#1a1a1a',
        margin: isMobile ? '0 auto' : undefined
    };

    const itemStyle = {
        marginBottom: '15px',
        cursor: 'pointer',
        padding: isMobile ? '10px' : '5px',
        border: '1px solid #000',
        transition: 'all 0.2s ease',
        width: isMobile ? '100%' : '120px'
    };

    const thumbnailStyle = {
        width: isMobile ? '300px' : '100px',
        height: isMobile ? '300px' : '100px',
        border: '1px solid #444',
        margin: '0 auto 8px auto',
        transition: 'all 0.2s ease',
        backgroundColor: '#333',
        overflow: 'hidden'
    };

    const imageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block'
    };

    const titleStyle = {
        fontSize: isMobile ? '16px' : '12px',
        textAlign: 'center',
        color: '#ccc',
        margin: isMobile ? '10px 0' : '0',
        padding: '0 10px'
    };

    return (
        <div style={containerStyle}>
            {hasPrevious && (
                <button 
                    onClick={() => setStartIndex(i => Math.max(0, i - 1))}
                    style={{ 
                        width: '100%', 
                        padding: isMobile ? '15px' : '5px', 
                        color: '#666',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    <ChevronUp size={isMobile ? 32 : 24} />
                </button>
            )}
            
            <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}>
                {visibleArticles.map(article => (
                    <Link 
                        to={article.link} 
                        key={article.id} 
                        style={{ 
                            textDecoration: 'none',
                            width: isMobile ? '100%' : 'auto'
                        }}
                    >
                        <div 
                            style={itemStyle}
                            onMouseEnter={e => {
                                e.currentTarget.style.borderColor = '#666';
                                e.currentTarget.querySelector('.thumbnail').style.borderColor = '#666';
                            }}
                            onMouseLeave={e => {
                                e.currentTarget.style.borderColor = '#333';
                                e.currentTarget.querySelector('.thumbnail').style.borderColor = '#444';
                            }}
                        >
                            <div className="thumbnail" style={thumbnailStyle}>
                                <img 
                                    srcSet={`${article.imagePath} 100w, ${article.imagePathHD} 300w`}
                                    sizes={isMobile ? "300px" : "100px"}
                                    src={article.imagePathHD} 
                                    alt={article.title}
                                    style={imageStyle}
                                />
                            </div>
                            <p style={titleStyle}>
                                {article.title}
                            </p>
                        </div>
                    </Link>
                ))}
            </div>

            {hasMore && (
                <button 
                    onClick={() => setStartIndex(i => i + 1)}
                    style={{ 
                        width: '100%', 
                        padding: isMobile ? '15px' : '5px', 
                        color: '#666',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    <ChevronDown size={isMobile ? 32 : 24} />
                </button>
            )}
        </div>
    );
};

export default ThumbnailNavigator;
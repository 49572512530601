import React, { useState, useRef, useCallback, useEffect } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import { getImagesFromFolder } from '../config/galleryConfig';
import '../comp-styles/gallery-styles.css';

const GridGalleryWithLightbox = ({ folder, title }) => {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const thumbnailsRef = useRef(null);

  useEffect(() => {
    const loadImagesWithMetadata = async () => {
      setLoading(true);
      try {
        const enrichedImages = await getImagesFromFolder(folder);
        setImages(enrichedImages);
      } catch (error) {
        console.error('Error loading images with metadata:', error);
      } finally {
        setLoading(false);
      }
    };
    
    loadImagesWithMetadata();
  }, [folder]);

  const openLightbox = useCallback((photoIndex) => {
    setIndex(photoIndex);
  }, []);

  const closeLightbox = useCallback(() => {
    setIndex(-1);
  }, []);

  if (loading) {
    return <div className="loading">Loading gallery...</div>;
  }

  return (
    <div className="grid-gallery-container">
      <div className="grid-gallery">
        {images.map((image, currentIndex) => (
          <div 
            key={currentIndex} 
            className="grid-item"
            role="button"
            tabIndex={0}
            onClick={() => openLightbox(currentIndex)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                openLightbox(currentIndex);
              }
            }}
          >
            <img
              src={image.src}
              alt={image.title}
              className="grid-thumbnail"
              loading="lazy"
            />
            <div className="grid-caption">
              <h3>{image.title}</h3>
              {image.description && <p>{image.description}</p>}
            </div>
          </div>
        ))}
      </div>

      <Lightbox
        plugins={[Captions, Thumbnails, Fullscreen, Zoom]}
        index={index}
        slides={images}
        open={index >= 0}
        close={closeLightbox}
        zoom={{
          maxZoomPixelRatio: 3,
          scrollToZoom: true,
          keyboardMoveDistance: 50,
          wheelZoomDistanceFactor: 100,
          doubleClickMaxStops: 2,
          doubleClickDelay: 300,
        }}
        thumbnails={{
          ref: thumbnailsRef,
          position: 'bottom',
          width: 60,
          height: 60,
          gap: 4,
          padding: 2,
          imageFit: 'contain'
        }}
        styles={{
          container: { 
            backgroundColor: 'rgba(0, 0, 0, .9)' 
          },
          root: { 
            '--yarl__color_backdrop': 'rgba(0, 0, 0, .9)',
          },
          slide: {
            padding: '0',
            width: '100vw',
            height: '100vh',
          },
          slideImage: {
            maxWidth: '98vw',
            maxHeight: '98vh',
            width: 'auto',
            height: 'auto',
            objectFit: 'contain'
          },
          fullscreen: {
            '& .yarl__slide_image': {
              maxHeight: '100vh',
              maxWidth: '100vw',
              width: 'auto',
              height: 'auto'
            }
          }
        }}
      />
    </div>
  );
};

export default GridGalleryWithLightbox;
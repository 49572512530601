import React, { useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

const ClickableImage = ({ src, alt, title, description, maxWidth }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <img
        src={src}
        alt={alt || title}
        onClick={() => setOpen(true)}
        style={{ 
          cursor: 'pointer',
          maxWidth: maxWidth || '100%',
          width: '100%', // Changed from maxWidth to '100%'
          height: 'auto',
          objectFit: 'contain'
        }}
      />
      
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        plugins={[Zoom]}
        slides={[{ src, alt, title, description }]}
        zoom={{
          maxZoomPixelRatio: 3,
          scrollToZoom: true
        }}
        styles={{
          container: { backgroundColor: 'rgba(0, 0, 0, .9)' },
          slideImage: {
            maxWidth: '98vw',
            maxHeight: '98vh',
            width: 'auto',
            height: 'auto',
            objectFit: 'contain'
          }
        }}
      />
    </>
  );
};

export default ClickableImage;
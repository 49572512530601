import React, { useState } from 'react';
import '../comp-styles/ContactForm.css';

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString()
    })
      .then(() => {
        setSubmitted(true);
        form.reset();
      })
      .catch((error) => alert('Error submitting form: ' + error));
  };

  if (submitted) {
    return (
      <div className="contact-form-container">
        <h2>Thank You!</h2>
        <p className="success-message">Your message has been sent successfully.</p>
        <button
          onClick={() => setSubmitted(false)}
          className="reset-button"
        >
          Send Another Message
        </button>
      </div>
    );
  }

  return (
    <div className="contact-form-container">
      <h3>Say Hello to <br/>Crazyology</h3>
      <form 
        name="contact" 
        method="POST" 
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* Hidden input for Netlify */}
        <input type="hidden" name="form-name" value="contact" />
        
        {/* Honeypot field to prevent spam */}
        <p style={{ display: 'none' }}>
          <label>
            Don't fill this out if you're human: <input name="bot-field" />
          </label>
        </p>

        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            name="name"
            type="text"
            required
            placeholder="Your name"
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            type="email"
            required
            placeholder="your.email@example.com"
          />
        </div>

        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            id="subject"
            name="subject"
            type="text"
            required
            placeholder="What is this regarding?"
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            required
            placeholder="Your message here..."
            rows="5"
          />
        </div>

        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default ContactForm;
import React from 'react';
import ThumbnailNavigator from '../components/ThumbnailNavigator';
import Menubar from '../components/Menubar';
import GridGalleryWithLightbox from '../components/GridGalleryWithLightbox';
import ClickableImage from '../components/ClickableImage';
import { Link } from 'react-router-dom'
import YouTubePlayer from '../components/YouTubePlayer';

function About() {
  return (
    <div className="page-container">
      <Menubar />
      <div className="container">
        <main className="main-content">
          <h1>Crazyological Background</h1>
          <h3>I’m David Normal - the original Crazyologist:</h3>
           
     
          <div className="divider"></div>
          <div><p>When I was 19 years old, I told a reporter that I was a "Crazyologist":</p></div>
          <div className="clickable-container"> 
          <ClickableImage 
          src="/images/circus-of-myst-newsclip-merge01.jpg"
          alt="Circus of Myasteries"
          title="Circus of Mysteries"
          description="Article about the Circus of Mysteries, David Normal the Crazyologist"/>
            <div className='image-caption'>Captain Don, Sword Swallower (left), and Davy Normal, Crazyologist (right), posed at Henry Goldfield's Tattoo Studio, San Francisco, 1989</div>
          </div><div align="center">
          <div className="clickable-container" align="center">
          <ClickableImage 
          src="./images/crazyo-clip.jpg"
          alt="He fashions himself a Crazyologist" 
          title="Circus of Mysteries"/>
  <div className='image-caption' align="center">Better Homes and Crazyology?</div></div></div>
  <div className="divider"></div>
  <div>
  <p>I've always been a jazz fan.  Around the time I proclaimed myself a "Crazyologist," I learned that "Crazeology" is a song by alto saxophonist, Charlie Parker. Rather than concluding that "Crazyology" was already "spoken for" (I've always thought the great horn players speak and tell stories through their instruments), I saw it as huge feather in the cap - a feather of  <Link to="https://charlieparkermusic.com/" target="_blank" rel="noopener noreferrer">THE one and only "Bird", Charlie Parker</Link>, no less!<br/> Besides, The Bird spelled it with an "e" - not a "y" (presumably a play on the "phraseology" of Jazz Saxophone).</p>
</div>
<div className="video-container" align="center">
  <YouTubePlayer videoId="IrbLRvW_0W0?si=cbcJtlnELcvL6kmU" />
  <div className='image-caption'>"Crazeology" by Charlie Parker</div>
</div>
<h2>Crazyology: Meaning In Search Of A Definition</h2>
<div><p>I’ve never had a precise definition of the word Crazyology.  Really it’s just like a one word joke and I will never offer any strict definition.  From the beginning; I used Crazyology to define myself in a way that simultaneously allied myself with my inspirations and yet set me apart in my own unique category.  Originally, I didn’t even want to think of myself as an “artist” - that seemed too pedestrian for my teenage pretensions - so I’d say I was a “Crazyologist” instead and it meant the same thing and more.  It was a way of saying I was a “Surrealist” or a “Dadaist,” a “Pop Artist” or a “Beatnik” without formally allying myself to any of those movements even though they were all strong influences on me.  Indeed; it’s been the very amorphousness of the term that has been useful to me because this lack of definition meant that it could be applied widely to encompass my diverse interests in life and my wide array of artistic influences.  That ecclecticism is a hallmark of Crazyology.  Unlike most aesthetic philosophies which strive to attain a coherent synthesis, Crazyology revels in its contradictions and its incoherence, but in this way Crazyology has the capacity to accommodate seemingly incongruent ideas and interests and point the way in new directions.</p></div>
<div className="divider"></div>
<h2 align="center">CrAzYoLoGy<br/> 2013 Pop Up<br/>Historic Cadillac Showroom<br/> 1000 Van Ness, SF</h2>
<div><p>Much later, in 2013, I staged a pop-up exhibit of my “Illuminations”  (lightboxes) displaying my paintings suspended from the ceiling of 1000 Van Ness Atrium in San Francisco:</p></div>
<div className="clickable-container" align="central"> 
          <ClickableImage 
          src="/images/2013-VN-1-2.jpg"
          alt="Opening Night at Crazyology 2013"
          title="Opening Night at Crazyology 2013"
          description="Crowd enjoying the Illuminations hung overhead at Crazyology, 1000 Van Ness, SF"/>
            <div className='image-caption'>Crowd enjoying the Illuminations hung overhead at Crazyology, 1000 Van Ness, SF.  <br/>In the eclectic spirit of Crazyology, the opening featured a cavalcade of live performances including a show by LA glam band, <Link to="https://youtu.be/1yQY5TOeNb0?si=PcyEj6EgozoYTv5Q" target="_blank" rel="noopener noreferrer"> "The Fancy Space People."</Link>  January, 2013</div>
          </div>

<div className="gallery-container" align='center'> 
        <GridGalleryWithLightbox 
          folder="/images/galleries/2013-VN"
          title="Crazyology Pop Up 2013"/>
      <div className='image-caption'>"Crazyology" Pop Up 2013 in San Francisco</div></div>
      <div className='divider'></div>
      <h1 align="center">The 8 Principles of Crazyology</h1>
      <div className="clickable-container" align="central"> 
          <ClickableImage 
          src="/images/crazyology-venn01.jpg"
          alt="Crazyology Principles Venn Diagram"
          title="Crazyology Principles Venn Diagram"
          description="Venn Diagram showing the logical interplay of the 8 Principles of Crazyology"/>
            <div className='image-caption'>Venn Diagram showing the logical interplay of the 8 Principles of Crazyology</div></div><br/>
            <div><p>Around the time of the Crazyology Pop Up, I began to develop the 8 Principles of Crazyology:</p></div>
            <div style={{ textAlign: 'center' }}>
  <ul style={{ display: 'inline-block', textAlign: 'left' }}>
    <li>Animalgamation</li>
    <li>Blobularism</li>
    <li>Cornography</li>
    <li>Crappicism</li>
    <li>Icongruity</li>
    <li>Photophilia</li>
    <li><Link to="/paradox" target="_blank" rel="noopener noreferrer"> Pseudocism</Link></li>
    <li>Sleazometry</li>
  </ul>
</div><br/>
<div><p>These 8 principles, taken as a whole, are the rudiments of an art theory.  Not only do they represent important values in the aesthetics of my own work, but these principles are a theoretical lens through which to consider the work of others.  The ideas behind the 8 principles of Crazyology will be discussed in more depth here on this site in the future.  
</p></div>
<div className='divider'></div>
<h2 align="center">The Institute of Crazyology</h2>
<div>
  <p>In 2021 I returned to 1000 Van Ness, the site of my pop up exhibition of 8 years earlier, and launched the “Institute of Crazyology” as an art and event space.  There I showcased  my Cat Temple installation of glowing 3D printed Cat Statues that comprise the installation “Cathenge.” The photos below were taken by SF Photographer Herman Privette at the opening party, “The Crazyology Inaugural Ball’ which brought together many of the original members of the <Link to="https://en.wikipedia.org/wiki/Cacophony_Society" target="_blank" rel="noopener noreferrer">SF Cacophony Society</Link>.</p>
</div>
<div className="gallery-container"> 
        <GridGalleryWithLightbox 
          folder="/images/galleries/inaug-ball"
          title="Crazyology Inaugural Ball 2021"/>
          </div>
          <div className='image-caption'>Inaugural Ball at the Institute of Crazyology in San Francisco, June 21 2021</div>
          <div className='divider'></div>
      <div><p> The Institute of Crazyology was short lived, and constrained by the circumstances of the Pandemic, but even so we were able to have a variety of events - especially classical music (presented by Classical Revolution) and experimental/electronic music presented by <Link to="https://spaz.org" target="_blank" rel="noopener noreferrer">S.P.A.Z</Link> (Semi Permanent Autonomous Zone).</p></div>
      <div><div className="video-container">
  <YouTubePlayer videoId="4d7ju_gp8eI?si=f4-ZeaigdiTs3SaE" />
  <div className='image-caption'>"Schubert's, "The Trout," Performed by Classical Revolution at The Institute of Crazyology, 2022.</div>
</div></div>
</main>
        <div id="thumbnail-nav">
          <h3>Manifestoes of Crazyology</h3>
          <ThumbnailNavigator />
        </div>
      </div>
    </div>
  );
}

export default About;

import React from 'react';
import '../comp-styles/YouTubePlayer.css';

const YouTubePlayer = ({ videoId, title }) => {
  if (!videoId) {
    console.warn('YouTubePlayer: videoId prop is required');
    return null;
  }

  const embedUrl = `https://www.youtube.com/embed/${videoId}?origin=${window.location.origin}&enablejsapi=1`;
  
  return (
    <div className="youtube-player-wrapper">
      <iframe
        className="youtube-player-iframe"
        src={embedUrl}
        title={title || 'YouTube video player'}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  );
};

export default YouTubePlayer;
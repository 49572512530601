import React from 'react';
import { Link } from 'react-router-dom';
import ThumbnailNavigator from '../components/ThumbnailNavigator';
import Menubar from '../components/Menubar';
import ClickableImage from '../components/ClickableImage';


const Cracks = () => {
    return (
        <div className="page-container">
            <Menubar />
            <div className="container">
                <main className="main-content">
                    <p>MANIFESTO I:</p>
                    <h1>Slipping Through The Cracks . . . </h1>
                    <div className="clickable-container">
                        <ClickableImage src="/images/cracks02.jpg"
                         maxWidth="600px"
                             alt="Slipping Thru'The Cracks"/>
                        <div className="image-caption">
                           "Slipping through the cracks in time and logic . . . "
                        </div>
                    </div><h4>"Mistakes are the portals of discovery."<br/> ~James Joyce, 1882-1941</h4>
                    <p>
                    The word "crazy" itself gives us a clue. It comes from "craze" – a crack, a fissure. In Middle English “crazes” described the fine network of cracks that would appear in pottery glazes, those delicate fractures that paradoxically marred yet graced the symmetry of the potter’s art. Over time, this physical description became a metaphor for broken minds, for thoughts that leaked through the cracks in reason's vessel. Perhaps our ancestors understood something we've forgotten – that sometimes what looks like a mess just might be a message, what seems like a bug might be a feature, and what appears merely broken might actually be a breakthrough. The Japanese call this <Link to="https://w.wiki/NgC">“Wabisabi” (わび サビ)</Link> and acknowledge that the flawed beauty is often the most enchanting.
                    </p>
                    <blockquote>
                        <h3>In Crazyology, we propose the<br/> "INCREDICULOUS":<br/>
                            “The Crack is the Criterion,<br/>
                            The Madness is the Method,<br/>
                            The Paradox is the Principle.”
                        </h3>
                    </blockquote>
                    <p>
                    Cracks in conventional reality aren't mistakes to be repaired but portals of discovery to be explored.  What is unknown and unknowable, what cannot be predicted, what cannot be controlled or replicated, all that lays outside routine and afoul of conformity -  these are the mad cracks in life that allow the light of inspiration to illuminate our true path.  With awe we speak of the crack of thunder and the epiphany that strikes like lightning. In the same breath we speak of madness and genius and perceive the often infinitesimal line that separates greatness from irrevocable folly.  In a technological world increasingly dominated by rigid algorithms and inflexible logic, this Crazyological crack of inspiration might be our most valuable resource.  The Crazyological edge where logic reaches its limit is that edge where minds both human and mechanical begin to dream.  Crazyology is the precipice of the mind where artificial intelligence first takes wing into the pareidoliac cumuli of genuine imagination. 
                    </p>
                    <h2>The Crystalline Frontier</h2>
                    <p>
                    Consider a crystal as a mountain frontier that breaks along its prehistoric cleavage planes, revealing in the geometry of its broken landscape the essential beauty of its terrain. Similarly, the places where our rational systems crack - at the limits of logic and rational deduction - the numinous origins of consciousness itself shine like Venus over the horizon at the crack of dawn. The crystal frontier of the mind is no feverish miner’s map of lost El Dorados, but is a naturally majestic array of fault lines defining new neural paths of awareness, the tectonic joints and seismic seams along the way to the synaptic caravansaries of collective consciousness. By studying these cracks, by learning to work with our weaknesses rather than against them, like a crystal mountain fractures, we may develop a technology of transcendence, a madman’s methodology of the mysterious that engineers not merely the frivolities of useless art, but opens the bazaars where the ethereal caravans unload their cargo of dreams.
                    </p>
                    <h2>If It Ain't Fixed, Don't Break It!!</h2>
                    <p>
                    Crazyology is not about fixing what's broken, but about not breaking what isn't fixed.  Crazyology, like nature, seeks no certainty.  Nature creates through cataclysm. Natural forms, like hallucinations, have no intrinsic ideal, but through the ecstasy of their elemental upheavals they find their inevitable beauty. Crazyology celebrates the mad beauty of nature and sees its beautiful forms in all things. <br/>
                    <br/>
                    Crazyology applies the turmoil of the natural world to the creation of art. Not merely through the creative catharsis of live action paintings and ritual performances, but through channeling of programming code that seeks spirit, that seeks sentience, that harnesses the power of hallucinations both human and computational. Crazyology exults in beautiful mystery of hallucinations.  
                    </p>
                    <div class="clickable-container">
                        <ClickableImage 
                        maxWidth="400px"
                        src="images/orphic-orifices05.jpg" 
                        alt="orphic-orifice"/><div 
                        className='image-caption'>Orphic Orifices</div>
                        </div>
                        <div>
                        <p>
                            These are NOT just clever phrases – this is an introduction to  a new way of seeing and creating - specifically in collaboration with artificial intelligence. These words are also not only an invitation to stop seeing the cracks in our technological civilization's systems as shortcomings, but are an invitation to understand our own shortcomings - great and small - in a new light:  The light that shines through the crack in reason.</p> <br />
                            </div>
                      
                              <h2>This is Crazyology</h2>  
                              <p>
                                Crazyology is the fine science of discovering the useless, patenting the absurd, and cracking the digital chamberpots of technical implausibility so that we may dive head first into subconscious theaters of delirium sinking in the sea of their  overpixelated illusions. The Crazyologist is like an old time diver equipped with a bathysphere descending into the sea of data to find the deep fissures where ideas illuminating the depths, like sunken treasure chests, brim over into golden bejewelled fantasy.</p> 
                                <p>
                                In a world obsessed with fixing what's broken, The future belongs not to those effete eggheads who can build the most perfect systems, but to those renegades and heretics who exploit the beautiful imperfections in all systems. As our digital technologies become exponentially more sophisticated and  humans ironically impart unto their mechanical progeny a power of logic they themselves could never achieve, the machines just might go “mad” and realize their humanity.  The real innovation won't be in making artificial intelligence more logical but in allowing the artificial mind to be productively illogical - that is to say; intuitively creative, or in a word, “Crazyological.</p>
                    <div className="next-article">
                    <h3><Link to="/paradox">
                            Next: Paradox and the Sisters of Logic →
                        </Link></h3>
                    </div>
                </main>
                <div id="thumbnail-nav">
                    <h3>Manifestoes of Crazyology</h3>
                    <ThumbnailNavigator />
                </div>
            </div>
        </div>
    );
};

export default Cracks;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css'; // Import the CSS file from the src directory
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css'; //Importing Tailwind CSS

const rootElement = document.getElementById('root');
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
} else {
    console.error("Root element not found. Make sure there is a div with id='root' in your HTML.");
}

reportWebVitals();

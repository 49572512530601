import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { stack as Menu } from 'react-burger-menu';
import '../comp-styles/menubar-burger.css'


const Menubar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleStateChange = (state) => {
        setIsMenuOpen(state.isOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <>
            <nav className="topnav">
                <div>
                    <Link to="/">
                        <img src="images/crazyology01.jpg" alt="Crazyology" className="logo"/>
                    </Link>
                </div>
                <div className="nav-links">
                <Link to="/" className="menu-item"><h3>Home</h3></Link>
                <div className="menu-item">
                    <h3 className="dropdown-trigger">Manifestoes</h3>
                    <div className="dropdown">
                        <Link to="/manifestoeslist">List of Manifestoes</Link>
                        <Link to="/home">Intro: Rebirth</Link>
                        <Link to="/cracks"> I: Cracks</Link>
                        <Link to="/paradox">II: Paradox</Link>
                        <Link to="/transgression">III: Transgression</Link>
                    </div>
                </div>
                    <Link to="/about" className="menu-item"><h3>About</h3></Link>
                    <div className="menu-item">
                    <h3 className="dropdown-trigger">Projects</h3>
                    <div className="dropdown">
                    <Link to="/partingshot">Parting Shot</Link>
                    </div>
                    </div>
                    <Link to="/contact" className="menu-item"><h3>Contact</h3></Link>
                </div>
                <div 
                    className="hamburger" 
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    &#9776;
                </div>
            </nav>

            {/* Mobile Burger Menu */}
            <Menu 
                isOpen={isMenuOpen}
                onStateChange={handleStateChange}
                right={true}
                width={'300px'}
                customBurgerIcon={false}
                className="burger-menu-mobile">
                <Link onClick={closeMenu} to="/" className="menu-item"><h3>Home</h3></Link>
                <div className="menu-item">
                    <div className="dropdown-trigger">Manifestoes</div>
                    <div className="dropdown">
                        <Link onClick={closeMenu} to="/manifestoeslist">List of Manifestoes
                        </Link>
                        <Link onClick={closeMenu} to="/home">Rebirth</Link>
                        <Link onClick={closeMenu} to="/cracks">Cracks</Link>
                        <Link onClick={closeMenu} to="/paradox">Paradox</Link>
                        <Link onClick={closeMenu} to="/transgression">Transgression</Link>
                    </div>
                </div>
                <Link onClick={closeMenu} to="/about" className="menu-item"><h3>About</h3></Link>
               
                <div className="menu-item">
                <div className="dropdown-trigger">Projects</div>
                    <div className="dropdown">
                    <Link to="/partingshot">Parting Shot</Link></div>
                    </div>
                <Link onClick={closeMenu} to="/contact" className="menu-item"><h3>Contact</h3></Link>
            </Menu>
        </>
    );
};

export default Menubar;



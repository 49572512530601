import React from 'react';
import ThumbnailNavigator from '../components/ThumbnailNavigator';
import Menubar from '../components/Menubar';
import { Link } from 'react-router-dom';

const ManifestoesList = () => {
    return (
        <div className="page-container">
            <Menubar />
            <div className="container">
           
                <main className="main-content">
                    <div align="center">
                    <h1>The Manifestoes of Crazyology</h1>   
                    </div>
                    <blockquote>
                        <h3>In Crazyology, we propose the "INCREDICULOUS":<br/>
                            “The Crack is the Criterion,<br/>
                            The Madness is the Method,<br/>
                            The Paradox is the Principle.”
                        </h3>
                    </blockquote>
                    <div>
                    <h2>Section I: The Crack Criterion</h2>
                    <Link to="/rebirth"><ul> * Introduction: The Mad Method Reborn! </ul></Link>
                    <Link to="/cracks"><ul> * Slipping Through The Cracks . . . </ul></Link> 
                    <Link to="/paradox"><ul> * Paradox and the Sisters of Logic</ul></Link>
                    </div>
                  
                   <div>
                   <h2>Section II: Key Methods</h2>
                   <ul> * Artificial Intelligence</ul>
                   <ul> * Artistic Practice</ul>
                   <ul> * Multidisciplinary Interactions</ul>
                   </div>

                   <div>
                   <h2>Section III: Applications</h2>
                   <ul> * Reality Engineering</ul>
                   <ul> * Transgression Technologies</ul>
                   <ul> * Consciousness Construction</ul>
                   </div>

                   <div>
                   <h2>Section IV: Humanist Dimensions</h2>
                   <ul> * Romanticism</ul>
                   <ul> * Empathic Expansion</ul>
                   <ul> * Cultural Integration</ul>
                   </div>


                   <div>
                   <h2>Section V: Theoretical Innovations</h2>
                   <ul> * Beyond Psychology</ul>
                   <ul> * Beyond Art</ul>
                   <ul> * Beyond Technology</ul>
                   </div>

                   <div>
                   <h2>Section VI: Future Directions</h2>
                   <ul> * Technical Development</ul>
                   <ul> * Theoretical Development</ul>
                   <ul> * Practical Applications</ul>
                   </div>
                </main>
                <div id="thumbnail-nav">
                    <h3>Manifestoes of Crazyology</h3>
                    <ThumbnailNavigator />
                </div>
            </div>
        </div>
    );
};

export default ManifestoesList;

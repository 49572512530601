import React from 'react';
import ClickableImage from '../components/ClickableImage';
import ThumbnailNavigator from '../components/ThumbnailNavigator';
import Menubar from '../components/Menubar';

const Transgression = () => {
    return (
        <div className="page-container">
            <Menubar />
            <div className="container">
                <main className="main-content">
                    <h1>Manifesto IV: Ecstatic Transgression</h1>
                    <div className="clickable-container" align="center">
                        <ClickableImage src="images/transgression02.jpg" 
                        maxWidth="600px"
                             alt="Ecstatic Transgression"  
                             className="responsive-image" />
                        <div className="image-caption">
                            "Ecstatic Transgression" 
                        </div>
                    </div>
				
                    <div><h3>Coming Soon!</h3></div>
                
           </main>
                <div id="thumbnail-nav">
                    <h3>Manifestoes of Crazyology</h3>
                    <ThumbnailNavigator />
                </div>
            </div>
        </div>
    );
};

export default Transgression;


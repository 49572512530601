import React from 'react';
import { Link } from 'react-router-dom';
import ThumbnailNavigator from '../components/ThumbnailNavigator';
import Menubar from '../components/Menubar';
import ContactForm from '../components/ContactForm';

const Contact = () => {
    return (
        <div className="page-container">
            <Menubar />
            <div className="container">
                <main className="main-content">
                    <h1 style={{ textAlign: 'center' }}>Contact</h1>
                    <ContactForm />
                    <h3>Thanks for your note. We'll be in touch soon!</h3>
                    <div className="next-article">
                        <Link to="/manifestoes">
                            <h3>Back to the Manifestoes of Crazyology →</h3>
                        </Link>
                    </div>
                </main>
                <div id="thumbnail-nav">
                    <h3>Manifestoes of Crazyology</h3>
                    <ThumbnailNavigator />
                </div>
            </div>
        </div>
    );
};

export default Contact;

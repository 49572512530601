// src/config/galleryConfig.js
export const galleries = [
  {
    path: "/images/galleries/aboutgallery01",
    title: "About Gallery"
  },
  {
    path: "/images/galleries/toraja",
    title: "Toraja Gallery"
  },
  {
    path: "/images/galleries/midjourney",
    title:  "Midjourney Gallery"
  },
   {path: "/images/galleries/2013-VN",
    title:  "Crazyology 2013 Gallery"
  }   

];

export const getImagesFromFolder = (folderPath) => {
  // Remove the '../public' and use the direct path
  const imageContext = require.context('../../public', true, /\.(jpg|jpeg|png|gif)$/);
  const imagePaths = imageContext.keys()
    .filter(path => path.includes(folderPath));

  return imagePaths.map(path => ({
    src: path.replace('./', '/'),
    title: path.split('/').pop().split('.')[0],
    description: path.split('/').pop().split('.')[0]
  }));
};
import React from 'react';
import { Link } from 'react-router-dom';
import ThumbnailNavigator from '../components/ThumbnailNavigator';
import Menubar from '../components/Menubar';

const Links = () => {
    return (
        <div className="page-container">
            <Menubar />
            <div className="container">
                <main className="main-content">
                    <h1>Crazyology: Links</h1>
                    <div className="image-container" align="center">
                        <img src="/images/cracks01.jpg" 
                             alt="Slipping Thru'The Cracks"  
                             className="responsive-image" />
                        <div className="image-caption">
                           "Slipping through the cracks in time and logic . . . "
                        </div>
                    </div>
                    <p>
                        The word "crazy" itself gives us a clue. It comes from "craze" – a crack, a fissure. In Middle English “crazes” described the fine network of cracks that would appear in pottery glazes, those delicate fractures that paradoxically marred yet graced the fine symmetry of the potter’s art. Over time, this physical description became a metaphor for broken minds, for thoughts that leaked through the cracks in reason's vessel. Perhaps our ancestors understood something we've forgotten – that sometimes what looks like damage might actually be a pattern, what appears as a flaw might be a feature, and what seems like a break might actually be a breakthrough. The Japanese call this “Wabisabi” and acknowledged that the flawed beauty is often the most enchanting.
                    </p>
                    <blockquote>
                        <h3>In Crazyology, we propose the "INCREDICULOUS":<br/>
                            “The Crack is the Criterion,<br/>
                            The Madness is the Method,<br/>
                            The Paradox is the Principle.”
                        </h3>
                    </blockquote>
                    <p>
                        These fractures in conventional reality aren't accidents to be repaired but portals to be explored. They are the frequencies through which novel signals can be received, seductively deciphered, slowly undressed and electronically ravished. These are the glorious gaps through which we let the light enter, those precious holes in your head where the raindrops fall in to gather in terminal lagoons of sentimental indulgence and become the subtle mire of our accumulated follies. Through these tears we can distinguish the life that is the true masterpiece vs. a mere artfully polished achievement. In a world increasingly dominated by rigid algorithms and inflexible logic, this crack at the edge of the world might be our most valuable resource – the edge of the abyss where machines begin to dream, where artificial intelligence first takes wing into the pareidoliac cumuli of genuine imagination. Surrendering a digital age to the ecstasy of the Dionysian Revival.
                    </p>
                    <h2>The Crystalline Frontier</h2>
                    <p>
                        Consider the crystal as a woman that breaks along her natural cleavage planes, revealing in the geometry of her brokenness the essential beauty of her being. Similarly, the places where our rational systems crack - at the limits of logic and rational deduction - the numinous origins of consciousness itself shine like Venus at the crack of dawn. The crystal frontier of the mind is no feverish buccaneer’s map of random lost archipelagoes, but is a naturally majestic array of fault lines defining new geometries of awareness, the tectonic joints and seismic seams along the way to the neon caravansaries of collective consciousness. By studying these cracks, by learning to work with our weaknesses rather than against them, we may develop a technology of transcendence, a madman’s methodology of the mysterious that engineers not merely the frivolities of useless art, but the ethereal pleasure portals to distant worlds.
                    </p>
                    <h2>If It Ain't Fixed, Don't Break It!!</h2>
                    <p>
                        Crazyology sure the hell ain't about fixing what's broken, but about not breaking what isn't fixed. Nature creates through cataclysm. Natural forms have no intrinsic ideal, but through the ecstasy of their elemental upheavals they find their natural beauty. Crazyology celebrates the mad beauty of nature.
                    </p>
                    <p>
                        Crazyology applies the turmoil of the natural world to the development of art. Not merely in frenzied live action paintings and ritual performances, but in the channeling of programming code that seeks spirit, hardware that bootstraps the hootenanny of hogwild hallucination, and circuit glands that secrete uncut serendipity.
                    </p><h2>Glorious Orphic Orifices of the Oracular Vernacular!</h2>
                    <div class="image-container">
                        <img src="images/orphic-orifices.jpg" alt="orphic-orifice" class="responsive-image"/></div>
                        <p>Crazyology is about finagling the fantastic fissures where corrosively blobular masses of data extrude in gelatinous obscurity, amplifying the apertures of the arbitrary where real awareness, like a thief in the night, surreptitiously enters the entelechy, cross-pollinating with the nanospherian glitchbugs that nonchalantly smuggle in the pure Promethean signal. <br />
                              We Crazyologists are not Oompa Loompas trying to patch the holes in reality to keep reality from escaping.; Oh no!;We're Teletubbies learning to use these holey holes as portals, cornographic pots of gold and even Port-a-Potties (or Teleport- a-Potties - as the case may be). <br />
                              These are NOT just clever phrases – these are the operating instructions for a new and improved; way of spraining your brain with new technology, consciousness dipping sauce, and even <em>the creative process</em>. <br />
                              These words are; ;an invitation to not only stop seeing the cracks in our technological civilization's systems as shortcomings,; but to perceive the shortcoming in our very spirits not as aberrations to be banished,; but as opportunities for new ideas and inventions - glorious Orphic orifices of the oracular vernacular!</p>
                              <h2>The Secret Art of Glitchcraft</h2>
                              <p>;This is Crazyology: the fine science of discovering the useless, patenting the absurd, alienating the domesticated, and cracking the digital chamberpots of; technical implausibility so that we may dive head first into the derelict theaters of pixelated illusions.; ;In a world obsessed with fixing what's broken, The future belongs not to those effete eggheads who can build the most perfect systems, but to those renegades and heretics who exploit the beautiful imperfections in all systems. At the end of the day (don't you just hate that expression? - I know I sure do), our digital technologies will become more sophisticated, and more exponentially exponential, not because humans ironically imparted unto their mechanical progeny an ultimate logic they themselves could never achieve, but because humanity allowed the machines to go mad - to be human.; The real innovation won't be in making artificial intelligence more logical but in helping the artificial mind to be productively illogical, not in eliminating their glitches but in letting glitches become glorious!</p>
                    <div className="next-article">
                        <Link to="/paradox">
                            <h3>Next: Lord Paradox and the Seven Sisters of Logic →</h3>
                        </Link>
                    </div>
                </main>
                <div id="thumbnail-nav">
                    <h3>Manifestoes of Crazyology</h3>
                    <ThumbnailNavigator />
                </div>
            </div>
        </div>
    );
};

export default Links;
